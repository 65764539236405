import styles from "./plan.module.scss";
import icon1 from "../../assets/images/icon-planet-earth.webp";
import icon2 from "../../assets/images/icon-heart.webp";
import icon3 from "../../assets/images/icon-palm.webp";
import icon4 from "../../assets/images/icon-handshake.webp";
import { Item } from "./item";
import { useTranslation } from "react-i18next";

export const Plan = () => {
    const { t } = useTranslation();

    return (<div className={styles.plan}>
        <h2 className={styles.title}>{t("plan.title")}<span className={styles.red}>{t("plan.title2")}</span></h2>

        <div className={styles.items} >

            <Item text={t("plan.text")} icon={icon1} width={44} />
            <Item text={t("plan.text2")} icon={icon2} width={46} />
            <Item text={t("plan.text3")} icon={icon3} width={33} />
            <Item text={t("plan.text4")} icon={icon4} width={54} />
        </div>
    </div>);
};
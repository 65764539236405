import { useEffect, useRef, useState } from "react";
import styles from "./history.module.scss";

export const Card = ({ img, cardClass = "", side = "" }) => {
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.1 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);
    return (
        <div ref={cardRef} className={`${styles.card} ${cardClass} ${isVisible ? styles.visible : ""} ${side === "left" ? styles.cardLeft : side === "right" ? styles.cardRight : ""
            }`}> <img src={img} className={styles.cardImg} /></div>
    );
};
import img1 from '../../assets/images/line-img1.webp';
import img2 from '../../assets/images/line-img2.webp';
import img3 from '../../assets/images/line-img3.webp';

import styles from './StatLines.module.scss';

function Line() {
  return (
    <div className={styles.line}>
      <p>
        <span className={styles.valueColor1}>#ONEWORLD</span>
      </p>
      <img className={`${styles.img} lazyload`} src={img1} width={213} height={199} alt="" />
      <p>
        <span className={styles.valueColor2}>#ONELIFE</span>
      </p>
      <img className={`${styles.img} lazyload`} src={img2} width={213} height={199} alt="" />
      <p>
        <span className={styles.valueColor3}>#ONELIFE</span>
      </p>
      <img className={`${styles.img} lazyload`} src={img3} width={213} height={199} alt="" />

    </div>
  );
}

function StatLines() {
  return (
    <section className={styles.statLines}>
      <div className={`${styles.lineWrap} ${styles.lineWrapRight}`}>
        <Line />
        <Line />
        <Line />
      </div>

      <div className={`${styles.lineWrap} ${styles.lineWrapLeft}`}>
        <Line />
        <Line />
        <Line />
      </div>
    </section>
  );
}

export default StatLines;

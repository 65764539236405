import Modal from "../modal/modal";
import certificate1 from "../../../assets/images/certificate-WGR.webp"
import certificate2 from "../../../assets/images/certificate-WGR2021.webp"
import styles from "./certificates-modal.module.scss"

const CertificatesModal = ({ isShowModal, onCloseModal }) => {
  return (
    <Modal isShowModal={isShowModal} onCloseModal={onCloseModal}>
      <div className={styles.certificates}>
        <img className={styles.certificate} src={certificate1} width={254} height={360} alt=""/>
        <img className={styles.certificate} src={certificate2} width={254} height={360} alt=""/>
      </div>
    </Modal>
  )
}

export default CertificatesModal

import styles from "./globus.module.scss";
import globus from "../../assets/images/globus.webp";
import globusm from "../../assets/images/globusm.webp";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export const Globus = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const { t } = useTranslation();

    return (
        <div className={styles.section}>
            <div className={styles.globus}>

                <h2 className={styles.title}>
                    <span className={styles.red}>{t("globus.title")}</span> {t("globus.title2")}
                </h2>

                <div className={styles.cityBlock}>
                    <span className={styles.item}>{t("globus.city")}</span>
                    <span className={styles.item}>{t("globus.city1")}</span>
                    <span className={styles.item}>{t("globus.city2")}</span>
                    <span className={styles.item}>{t("globus.city3")}</span>
                    <span className={styles.item}>{t("globus.city4")}</span>
                    <span className={styles.item}>{t("globus.city5")}</span>

                    <span className={styles.item}>{t("globus.city6")}</span>
                    <span className={styles.item}>{t("globus.city7")}</span>
                    <span className={styles.item}>{t("globus.city8")}</span>
                    <span className={styles.item}>{t("globus.city9")}</span>
                    <span className={styles.item}>{t("globus.city10")}</span>
                    <span className={styles.item}>{t("globus.city11")}</span>

                    <span className={styles.item}>{t("globus.city12")}</span>
                    <span className={styles.item}>{t("globus.city13")}</span>
                    <span className={styles.item}>{t("globus.city14")}</span>
                    <span className={styles.item}>{t("globus.city15")}</span>
                    <span className={styles.item}>{t("globus.city16")}</span>
                    <span className={styles.item}>{t("globus.city17")}</span>

                    <span className={styles.item}>{t("globus.city18")}</span>
                    <span className={styles.item}>{t("globus.city19")}</span>
                    <span className={styles.item}>{t("globus.city20")}</span>
                    <span className={styles.item}>{t("globus.city21")}</span>
                    <span className={styles.item}>{t("globus.city22")}</span>
                    <span className={styles.item} style={{ whiteSpace: "normal" }}>{t("globus.city23")}</span>

                    <span className={styles.item}>{t("globus.city24")}</span>
                    <span className={styles.item}>{t("globus.city25")}</span>
                    <span className={styles.item}>{t("globus.city26")}</span>
                    <span className={styles.item}>{t("globus.city27")}</span>
                    <span className={styles.item}>{t("globus.city28")}</span>
                    <span className={styles.item}>{t("globus.city29")}</span>

                    <span className={styles.item}>{t("globus.city30")}</span>
                    <span className={styles.item}>{t("globus.city31")}</span>
                    <span className={styles.item}>{t("globus.city32")}</span>
                    <span className={styles.item}>{t("globus.city33")}</span>
                    <span className={styles.item}>{t("globus.city34")}</span>
                    <span className={styles.item}>{t("globus.city35")}</span>

                </div>

                <div className={styles.globusBlock}>
                    <img className={styles.img} src={isMobile ? globusm : globus} />

                </div>
            </div>
        </div>
    );
};
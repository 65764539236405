import styles from "./plan.module.scss";


export const Item = ({ icon, text, width = 40 }) => {
    return (
        <div className={styles.item}>
            <img width={width} src={icon} alt="" />
            <span className={styles.text}>{text}</span>
        </div>
    );
};
import { useEffect, useRef } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from "react-responsive";
import run from "../../assets/images/run.webp";
import run2 from "../../assets/images/run2.webp";
import runMobile from "../../assets/images/run_mobile.webp";
import runMobile2 from "../../assets/images/run_mobile2.webp";
import styles from "./mission.module.scss";
import { useTranslation } from "react-i18next";

export const Mission = () => {
    gsap.registerPlugin(ScrollTrigger);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { i18n, t } = useTranslation();
    const lang = i18n?.resolvedLanguage;
    const textRef = useRef([]);
    const textColorRef = useRef(null);
    const textSizeRef = useRef(null);
    const containerTextColorRef = useRef(null);

    useEffect(() => {
        const mm = gsap.matchMedia();
        const chars = textRef.current;

        if (containerTextColorRef.current && textRef.current && textSizeRef.current) {
            const triggerContainerTextColor = containerTextColorRef.current;
            const totalChars = chars.length;

            chars.forEach((char, index) => {
                ScrollTrigger.create({
                    trigger: triggerContainerTextColor,
                    start: '15% bottom',
                    end: 'center bottom',
                    markers: false,
                    onUpdate: (self) => {
                        const progress = self.progress;
                        const charProgress = index / totalChars;
                        if (progress > charProgress) {
                            gsap.to(char, { color: '#232323' });
                        } else {
                            gsap.to(char, { color: '#E2E2E2' });
                        }
                    },
                    scrub: true,
                });
            });

            const scrollTriggerVars = {
                trigger: triggerContainerTextColor,
                start: '85% bottom',
                end: 'bottom top',
                markers: false,
                toggleActions: 'play none none reverse',
            };

            const tl = gsap.timeline({
                scrollTrigger: {
                    ...scrollTriggerVars,
                },
            });

            const t2 = gsap.timeline({
                scrollTrigger: {
                    ...scrollTriggerVars,
                    start: '95% bottom',
                },
            });

            mm.add('(min-width: 769px)', () => {
                tl.fromTo(textSizeRef.current, { fontSize: '20px' }, { fontSize: '32px' });
            });

            mm.add('(max-width: 768px)', () => {
                t2.fromTo(textSizeRef.current, { fontSize: '6px' }, { fontSize: '32px' });
            });

            return () => {
                ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            };
        }
    }, [isMobile]);

    const title = t("mission.transitionText");
    const splitText = title.split('');

    return <div className={styles.container}>
        <div className={styles.titleWrap}>
            <h4 className={styles.title}>{t("mission.title")}</h4>
        </div>
        <section className={styles.about} ref={containerTextColorRef}>
            <h2 className={`${styles.transitionText} ${lang === 'zh' ? styles.transitionTextZh : ''}`} ref={textColorRef}>
                {splitText.map((char, i) => (
                    <span className={styles.text} ref={el => textRef.current[i] = el} key={i} style={{ transition: 'color 0.1s' }}>
                        {char}
                    </span>
                ))}
            </h2>

            <div className={styles.imgWrap}>
                <img className={`${styles.img} lazyload`} src={isMobile ? runMobile : run} alt="" />

                <p className={styles.desc} ref={textSizeRef}>
                    {t("mission.desc1")}<br />{t("mission.desc2")}<br />{t("mission.desc3")}
                </p>
            </div>
        </section>

        <div style={{ marginTop: "6rem", marginBottom: "2rem" }}><span className={styles.title}>{t("mission.oneRun")}</span><span className={styles.titleText}>{t("mission.oneRun1")} </span></div>
        <div className={styles.description}>
            {lang === 'zh' ? (
              <>
                  一年中最受关注、规模最大的跑步盛事 <br/>
                  同一天在所有参与国家同步举行
              </>
            ):(
              t("mission.event")
            )}
        </div>

        <div className={styles.foot}>
            <div className={styles.cards}>
                <div className={styles.block}>
                    <div className={styles.card}>
                        <div className={styles.items}>
                            <div className={styles.count}>110+</div>
                            <div className={styles.item}>{t("mission.city")}</div>
                        </div>
                    </div>
                    <div className={styles.cardBig}>
                        <div className={styles.items} style={{ alignItems: "center" }}>
                            <div className={styles.count} style={{ color: "white" }}>200 000+</div>
                            <div className={styles.item} style={{ color: "white" }}>{t("mission.members")}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.block} style={{ marginTop: "20px" }}>
                    <div className={styles.cardBig} style={{ backgroundColor: "#FF3100" }}>
                        <div className={styles.items} style={{ alignItems: "center" }}>
                            <div className={styles.count} style={{ color: "white" }}>250 000+</div>
                            <div className={styles.item} style={{ color: "white" }}>{t("mission.users")}</div>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.items} style={{ alignItems: "center" }}>
                            <div className={styles.count}>15</div>
                            <div className={styles.item}>{t("mission.utc")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <img className={styles.imgFoot} src={isMobile ? runMobile2 : run2} />
        </div>
    </div>;
};
import { useTranslation } from "react-i18next";
import styles from "./format.module.scss";

export const Format = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>

            <div className={styles.title}>
                {t("format.title")}
            </div>
            <div className={styles.formats}>
                <div className={styles.format}>{t("format.format1")}</div>
                <div className={styles.format1} >{t("format.format2")}</div>
                <div className={styles.format2} >{t("format.format3")}</div>
                <div className={styles.format3}>{t("format.format4")}</div>
            </div>

        </div>
    );
};
import React, { useState, useEffect } from "react";
import styles from "./history.module.scss";
import { useMediaQuery } from "react-responsive";
import zabeg20171 from "../../assets/images/zabeg20171.webp";
import zabeg20172 from "../../assets/images/zabeg20172.webp";
import zabeg20201 from "../../assets/images/zabeg20201.webp";
import zabeg20202 from "../../assets/images/zabeg20202.webp";
import zabeg20231 from "../../assets/images/zabeg20231.webp";
import zabeg20232 from "../../assets/images/zabeg20232.webp";
import zabeg20241 from "../../assets/images/zabeg20241.webp";
import zabeg20242 from "../../assets/images/zabeg20242.webp";
import zabeg20251 from "../../assets/images/zabeg20251.webp";
import zabeg20252 from "../../assets/images/zabeg20252.webp";
import icon from "../../assets/images/icon.png";
import { Card } from "./card";
import { useTranslation } from "react-i18next";
import CertificatesModal from "../modals/certificates-modal/certificates-modal";

export const History = () => {
    const years = [2017, 2020, 2023, 2024, 2025];
    const [activeIndex, setActiveIndex] = useState(0);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
    const { t } = useTranslation();
    const offset = isMobile ? 5100 : 3800;
    const [isShowCertificatesModal, setShowCertificatesModal] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            const stepHeight = window.innerHeight / years.length;

            const newIndex = Math.min(
                years.length - 1,
                Math.max(0, Math.floor((scrollPosition - offset) / stepHeight))
            );

            setActiveIndex(newIndex);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [years.length, offset]);

    return (
        <div className={styles.history}>
            <div className={styles.wraptitle}>
                <h2 className={styles.topTitle}>{t("history.topTitle")}{" "}<span className={styles.oneRun}>{t("history.oneRun")}</span></h2>
            </div>
            <div className={styles.section}>
                <div className={styles.wrap}>
                    <div>
                        <div className={styles.date}>{t("history.year1")}</div>
                        <div className={styles.text} style={{ marginTop: "20px", maxWidth: "400px" }}>{t("history.text1")}
                            <span className={styles.white}>{t("history.text2")}</span>
                            {t("history.text3")}
                            {" "}
                            {t("history.text4") && <span className={styles.zabeg}>{t("history.text4")}</span>}
                        </div>
                    </div>

                    <div className={styles.cards}>
                        <Card side="left" img={zabeg20171} cardClass={styles.card1} />
                        <Card side="left" img={zabeg20172} cardClass={styles.card2} />
                    </div>
                    <div style={{ marginTop: "450px" }}>
                        <div className={styles.date}>{t("history.year3")}</div>
                        <div className={styles.text} style={{ marginTop: "20px", maxWidth: "400px" }}>
                            {t("history.text9")}{" "}
                            {t("history.text10") && <span className={styles.zabeg}>{t("history.text10")}</span>}
                            {" "}{t("history.text11")}{" "}
                            <span className={styles.zabeg}>{t("history.text12")}</span></div>
                    </div>

                    <div className={styles.cards}>
                        <Card side="left" img={zabeg20231} cardClass={styles.card5} />
                        <Card side="left" img={zabeg20232} cardClass={styles.card6} />
                    </div>
                </div>

                <div className={styles.container}>
                    {years.map((year, index) => (
                        <div key={index} className={styles.stepContainer}>

                            <div className={styles.circleLineContainer}>
                                <div
                                    className={styles.circle}
                                    style={{ backgroundColor: activeIndex === index ? "#ff3100" : "#1CAED7" }}
                                >
                                    <div className={styles.innerCircle} />
                                </div>

                                {index < years.length - 1 && <div className={styles.line} />}
                            </div>
                        </div>
                    ))}

                    <div className={styles.foot}>
                        <div>
                            <div className={styles.date} style={{ marginTop: "40px", textAlign: "center" }}>{t("history.year5")}</div>
                            <div className={styles.text} style={{ marginTop: "20px", textAlign: "center", lineHeight: "38px" }}>
                                <span className={styles.white}><span className={styles.zabeg}>{t("history.text18")}</span>{" "}{t("history.text19")}</span><br />
                                <span className={`${styles.zabeg} ${styles.white}`}>{t("history.text20")}</span>
                                {t("history.text21")}</div>
                        </div>
                        <div className={styles.cards}>
                            <Card side="left" img={zabeg20251} cardClass={styles.card9} />
                            <Card side="right" img={zabeg20252} cardClass={styles.card10} />
                        </div>
                    </div>
                </div>
                {isTablet && <div className={styles.tablet}>
                    <div>
                        <div style={{ marginTop: "30px" }}>
                            <div className={styles.date} >{t("history.year1")}</div>
                            <div className={styles.text} style={{ marginTop: "20px", maxWidth: "315px" }}>{t("history.text1")}
                                <span className={styles.white}>{t("history.text2")}</span>
                                {t("history.text3")}
                                {t("history.text4") && <span className={styles.zabeg}>{t("history.text4")}</span>}
                            </div>
                        </div>

                        <div className={styles.cards}>
                            <Card side="right" img={zabeg20202} cardClass={styles.card11} />
                        </div>
                        <div className={`${styles.date} ${styles.zabeg2020}`}>{t("history.year2")}<div>
                            <div className={styles.text} style={{ marginTop: "20px", maxWidth: "450px", lineHeight: "37px" }}>
                                <span className={styles.zabeg}>{t("history.text5")}</span>
                                {" "}<span className={styles.white}>{t("history.text6")}</span>
                                {t("history.text7") && (
                                  <span className={styles.infoWrap}>
                                  <span className={`${styles.white} ${styles.zabeg}`}>{t("history.text7")}</span>
                                      {" "}
                                      <button className={styles.infoBtn} onClick={() => setShowCertificatesModal(true)}>
                                      <img width={25} height={25} src={icon} alt=""/>
                                  </button>
                              </span>
                                )}
                                {" "}
                                {t("history.text8")}
                            </div>
                        </div>
                            <div className={styles.cards}>  <Card side="right" img={zabeg20172} cardClass={styles.card12} /></div>
                        </div>
                        <div className={styles.zabeg2023}>
                            <div className={styles.date}>{t("history.year3")}</div>
                            <div className={styles.text} style={{ marginTop: "20px", maxWidth: "400px" }}>{t("history.text9")}{" "}
                                {t("history.text10") && <span className={styles.zabeg}>{t("history.text10")}</span>}
                                {" "}{t("history.text11")}<span className={styles.zabeg}>{t("history.text12")}</span></div>
                        </div>
                        <div className={styles.cards}>
                            <Card side="right" img={zabeg20251} cardClass={styles.card13} />
                        </div>
                        <div className={`${styles.date} ${styles.zabeg2024}`}>{t("history.year4")}<div>
                            <div className={styles.text} style={{ marginTop: "20px", maxWidth: "450px" }}>
                                {t("history.text13")}{" "}<span className={styles.zabeg}>{t("history.text14")}</span>{" "}
                                {t("history.text15")}<span className={styles.zabeg}>{t("history.text16")}</span>{" "}{t("history.text17")}
                            </div>
                        </div>
                        </div>
                        <div className={styles.cards}>
                            <Card side="right" img={zabeg20252} cardClass={styles.card14} />
                        </div>
                        <div className={styles.zabeg2025}>
                            <div className={styles.date}>{t("history.year5")}</div>
                            <div className={styles.text}><span className={styles.white}>
                                <span className={styles.zabeg}>{t("history.text18")}</span>
                                {t("history.text19")}</span><span className={`${styles.zabeg} ${styles.white}`}>{t("history.text20")}</span>{" "}
                                {t("history.text21")}</div>
                        </div>
                    </div>
                </div>}
                <div className={styles.right}>
                    <div className={styles.cards}>
                        <Card side="right" img={zabeg20201} cardClass={styles.card3} />
                        <Card side="right" img={zabeg20202} cardClass={styles.card4} />
                    </div>

                    <div style={{ marginTop: "20rem", marginLeft: "30px" }} className={styles.date}>{t("history.year2")}<div>
                        <div className={styles.text} style={{ marginTop: "20px", maxWidth: "450px", lineHeight: "37px" }}>
                            <span className={styles.zabeg}>{t("history.text5")}</span>
                            {" "}<span className={styles.white}>{t("history.text6")}</span>
                            {t("history.text7") && (
                              <span className={styles.infoWrap}>
                                  <span className={`${styles.white} ${styles.zabeg}`}>{t("history.text7")}</span>
                                  {" "}
                                  <button className={styles.infoBtn} onClick={() => setShowCertificatesModal(true)}>
                                      <img width={25} height={25} src={icon} alt=""/>
                                  </button>
                              </span>
                            )}
                            {" "}
                            {t("history.text8")}
                        </div>
                    </div>
                    </div>

                    <div className={styles.cards}>
                        <Card side="right" img={zabeg20241} cardClass={styles.card7} />
                        <Card side="right" img={zabeg20242} cardClass={styles.card8} />
                    </div>
                    <div style={{ marginTop: "24rem", marginLeft: "30px" }} className={styles.date}>{t("history.year4")}<div>
                        <div className={styles.text} style={{ marginTop: "20px", maxWidth: "450px" }}>
                            {t("history.text13")}{" "}<span className={styles.zabeg}>{t("history.text14")}</span>{" "}
                            {t("history.text15")}{" "}<span className={styles.zabeg}>{t("history.text16")}</span>{" "}{t("history.text17")}
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <CertificatesModal isShowModal={isShowCertificatesModal} onCloseModal={setShowCertificatesModal} />
        </div >
    );
};